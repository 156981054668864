import React from 'react';
import { getSecondsElapsed } from 'lib/DateTime';
import ContentTimestamp from 'components/ContentTimestamp';
import dayjs from 'dayjs';
import i18next from 'i18next';
import getDayjsLocale from 'lib/Locale/getDayjsLocale';
import PropTypes from 'prop-types';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const TimestampWithUpdatedText = ({
  vertical,
  datePublished,
  latestDate = '',
  eventEnded = false,
  dateCreated = '',
  isInitiallyPublished = false,
}) => {
  const timeElapsedFromPublish = getSecondsElapsed(datePublished);
  const timeElapsedFromLatestPost = getSecondsElapsed(latestDate);

  if (timeElapsedFromPublish < 60 && isInitiallyPublished) {
    return (
      <ContentTimestamp
        dateCreated={dateCreated}
        dateOnly={false}
        datePublished={datePublished}
        dateLabel=""
        delimiter=", "
        hideMicroData
      />
    );
  }

  if (timeElapsedFromLatestPost < 60) {
    return (
      <span data-testid="timestamp-with-updated-text--updated-now">
        {i18next.t('Updated now')}
      </span>
    );
  }

  if (eventEnded) {
    return (
      <span data-testid="timestamp-with-updated-text--event-ended">
        {i18next.t('Last updated ')}
        <ContentTimestamp
          dateCreated={dateCreated}
          dateOnly={false}
          datePublished={latestDate}
          dateLabel=""
          delimiter=", "
          hideMicroData
        />
      </span>
    );
  }

  const loc = getDayjsLocale(vertical);
  const fromNow = dayjs(latestDate).locale(loc).fromNow(true);

  return (
    <span>
      {i18next.t('updated_time_ago', { time: fromNow })}
    </span>
  );
};

TimestampWithUpdatedText.propTypes = {
  vertical: PropTypes.string.isRequired,
  latestDate: PropTypes.string,
  dateCreated: PropTypes.string,
  datePublished: PropTypes.string.isRequired,
  eventEnded: PropTypes.bool,
  isInitiallyPublished: PropTypes.bool,
};

